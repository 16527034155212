<template>
	
	<div class="cont sales new" id="p09"  style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 550px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999; ">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div class="c01" style="display: block;">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">
										<input type="text" placeholder="이름" maxlength="10"
											v-model="item.managerNm"
										/>
									</div>
									<div class="desc">
										<input type="number" placeholder="연락처"
											v-model="item.phoneNumber"
											:rules="[rules.max(item, 'phoneNumber', 12), rules.min(item, 'phoneNumber', 12)]"
											style="width: 70%; margin-right: 10px;"
										/>
										<v-btn class="success2" large
											@click="add"
											:disabled="item.managerNm && item.phoneNumber ? false : true"
										>추가</v-btn>
									</div>
								</div>
							</div>
							
							<div class="item_list"
								style="max-height: 350px; overflow: auto;"
							>
								<div class="item"
									v-for="(item, index) in items"
									:key="index"
								>
									<div class="title ml-10">{{ item.managerNm }}</div>
									<div class="desc" style="display: inline-block;">
										<span style="margin-top: 5px !important; display: inline-block;">{{ item.phoneNumber | makeTell }}</span>
										<v-btn class="danger float-right mr-10" small
											@click="$delete(items, index)"
										>삭제</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<v-row>
						<v-col>
							<v-btn
								class="success2" block tile
								:disabled="!items.length"
								@click="save"
							>회원가입 SMS 발신</v-btn>
						</v-col>
						<v-col>
							<v-btn
								class="default" block tile
								@click="close"
							>취소</v-btn>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	
	export default{
		name: 'AgentPopupItem'
		,props: ['user']
		,data: function(){
			return {
				program: {
					name: '영업점 추가'
				}
				,item: {
				}
				,items: [
				]
				,rules: rules
			}
		}
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/branch/joins'
						,data: this.items
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '회원가입 SMS가 발신되었습니다'})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.status})
				}finally{
					this.close()
				}
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
			,add: function(){
				this.$set(this.items, this.items.length, this.item)
				this.$set(this, 'item', { name: '', phoneNumber: ''})
			}
		}
		,created: function(){
		}
	}
</script>

<style>
	.sales.new input {display: inline-block; width: 100%; margin-bottom: 4px; padding: 15px 20px; border: 1px solid #C1C8D8; font-size: 13px; color: #000000;}
</style>












