<template>
	
	<div class="cont" id="p09"  style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 400px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div class="c01" style="display: block;">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">영업 담당자</div>
									<div class="desc">{{ item.salesName }}</div>
								</div>
								<div class="item">
									<div class="title">현재 상태</div>
									<div class="desc on"> <!-- 상태 on/off = class "on"-->
										<span 
											:class="item.status == 'Normal' ? 'success2-txt' : ( item.status == 'Pause' ? 'warning-txt' : 'danger-txt')">{{ item.statusName }}</span>
									</div>
								</div>
								<div class="item reason hide"> <!-- display 숨김처리 class "hide" -->
									<div class="title">사유</div>
									<div class="desc">
										계약만료
									</div>
								</div>
								<div class="item slt">
									<div class="title">변경</div>
									<div class="desc">
										<v-btn
											:class="{ success2: status == 'Normal'}"
											small
											style="margin-right: 5px;"
											v-model="status"
											@click="status = 'Normal'; statusName = '정상 사용'"
										>정상 사용</v-btn>
										
										<v-btn
											:class="{ warning: status == 'Pause'}"
											small
											style="margin-right: 5px;"
											v-model="status"
											@click="status = 'Pause'; statusName = '사용 정지'"
										>사용 정지</v-btn>
										
										<v-btn
											:class="{ danger: status == 'Termination'}"
											small
											v-model="status"
											@click="status = 'Termination'; statusName = '해지'"
										>해지</v-btn>
									</div>
								</div>
								<div class="item trea input_reason"> <!-- 사용정지, 해지 선택시 class "active" -->
									<div class="title">사유</div>
									<div class="desc">
										<textarea 
											v-model="reason"
										></textarea>
									</div>
								</div>
							</div>
							<div class="p-text"> <!-- display 숨김처리 class "hide" -->
								사용정지 시 모든 서비스 접속이 차단됩니다.
							</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<v-row>
						<v-col>
							<v-btn
								class="success"
								style="background: #00bfa5;"
								block tile
								@click="save"
							>변경</v-btn>
						</v-col>
						<v-col>
							<v-btn
								class=""
								style="background: #bbb;"
								block tile
								@click="close"
							>취소</v-btn>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'AgentPopupItem'
		,props: ['user', 'item']
		,data: function(){
			return {
				program: {
					name: '영업점 계정 상태 변경'
				}
				,status: this.item.status
				,statusName: ''
				,reason: ''
			}
		}
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/branch/status'
						,data: {
							orgSeq: this.item.orgSeq
							,userSeq: this.item.userSeq
							,reason: this.reason
							,status: this.status
						}
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '계정 상태가 변경되었습니다'})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.status})
				}finally{
					this.$emit('setAccount', { index: this.item.index, status: this.status, statusName: this.statusName })
				}
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
		}
		,created: function(){
		}
	}
</script>